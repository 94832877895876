import request from '../util/request'

//返回用户数据
export function GetDataInfo(data) {
    return request({
        url: '/api/XmhHr/QueryEmpSalaryShebaoMoneyToConfirm',
        method: 'post',
        data : data
    })
}

//签名确认保存
export function saveAutograph(data) {
    return request({
        url: '/api/WXProgramHR/ConfirmEmpSalaryShebaoMoney',
        method: 'post',
        data : data
    })
}

//上传图片
export function uploadSignImg(data,header) {
    return request({
        url: '/api/WXProgramHR/UploadSignNamePic',
        method: 'post',
        data : data,
    })
}
