<template>
  <div class="container">
    <div class="Box">
      <p class="tip" v-if="dataInfo.errcode !== 200">{{dataInfo.message}}</p>
      <div class="item">
        <div class="label">月份：</div>
        <div class="val">{{ dataInfo.salary_date }}</div>
      </div>
      <div class="item">
        <div class="label">部门：</div>
        <div class="val">{{ dataInfo.d_name }}</div>
      </div>
      <div class="item">
        <div class="label">姓名：</div>
        <div class="val">{{ dataInfo.emp_name }}</div>
      </div>
      <div class="item">
        <div class="label">工资金额：</div>
        <div class="val">{{ dataInfo.salary }}</div>
      </div>
      <div class="item">
        <div class="label">实际发放：</div>
        <div class="val"> {{ dataInfo.real_salary }} </div>
      </div>
    </div>
    <div class="canvasBox">
      <h2 style="margin-bottom: 10px;">签字版</h2>
      <div class="autograph">
        <vue-esign ref="esign" :height="350" :isCrop="false" :lineWidth="10" :lineColor="'#000'" />
      </div>
      <div class="operate">
        <button @click="handleReset">清空</button>
      </div>
      <div class="btn" v-if="dataInfo.errcode === 200">
        <p class="link no" @click="saveData(20)">拒绝</p>
        <p class="link yes" @click="saveData(10)">同意</p>
      </div>
    </div>
  </div>
</template>

<script>
import { GetDataInfo, saveAutograph, uploadSignImg } from "@/api/autograph";
export default {
  data(){
    return {
      dataInfo: {},
      openId: '',
    }
  },
  created(){
    this.openId = this.$store.state.info.openId;
    this.fetchData()
  },
  methods:{
    //获取用户数据
    async fetchData(){
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      const params = {
        openId: this.openId
      }
      const resultData = await GetDataInfo(params)
      this.$toast.clear()
      console.log(resultData);
      this.dataInfo = resultData;
      if(resultData.errcode !== 200){
        this.$toast.fail(resultData.message)
      }
    },

    //base64 --> imgFile
    base64ImgToFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const suffix = mime.split('/')[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },

    //保存数据（提交、拒绝）
    async saveData(num){
      const sign_name_pic = await this.handleGenerate();
      if(!sign_name_pic){
        return
      }
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true,
      });
      const params = {
        openId: this.openId,
        id: this.dataInfo.id,
        sign_name_pic,
        is_confirm: num
      }
      const {result,msg} = await saveAutograph(params);
      this.$toast.clear()
      this.$toast(msg);
      result && await this.fetchData()
    },

    //重置画布
    handleReset(){
      this.$refs.esign.reset()
    },

    //生成图片
    async handleGenerate () {
      const data = await this.$refs.esign.generate().catch(err => {this.$toast('请签字');})
      if(data){
          const file = this.base64ImgToFile(data);
          return await this.uploadImg(file)
      }
    },

    //上传画布图片
    async uploadImg(imgFile){
      let formData = new FormData();
      formData.append('file',imgFile);
      const resultData = await uploadSignImg(formData);
      if(!resultData.result){
        this.$toast(resultData.msg);
      }
      return resultData.filePath || '';
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  .tip{
    color: #F56C6C;
    font-size: 20px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .Box{
    box-sizing: border-box;
    padding: 30px 5%;
    .item{
      display: flex;
      font-size: 16px;
      line-height: 2.5;
      color: #333;
      .label{
        width: 120px;
      }
      .val{

      }
    }
  }
  .canvasBox{
    margin-left: 2%;
    margin-right: 2%;
    .autograph{
      background-color: #eaeaea;
    }
    .operate{
      margin-top: 10px;
    }
    .btn{
      padding-right: 2%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 10px;
      padding-bottom: 50px;
      .link{
        padding: 7px 22px;
        color: #fff;
        border-radius: 20px;
        margin-left: 15px;
        &.yes{
          background-color: #409EFF;
        }
        &.no{
          background-color: #F56C6C;
        }
      }
    }
  }
}
</style>
